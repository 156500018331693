<template>
  <div class="filter-sidebar-wrapper">
    <b-button
      v-b-toggle.filters-sidebar
      variant="outline-secondary"
      class="filter-button"
      :disabled="disableFilters"
      >
        <font-awesome-icon
          class="left-icon"
          :icon="['far', 'sliders-h']"
        />
        <span class="pl-2" v-if="!iconOnly">More filters</span>
      </b-button>

      <b-sidebar
        id="filters-sidebar"
        title="Filters"
        :header-class="[isIos ? 'filters-sidebar-ios' : '']"
        right
        shadow
        :no-close-on-route-change="noCloseOnRouteChange"
        @hidden="toggleMainScroll"
        @shown="toggleMainScroll"
      >
        <template #header="{ hide }">
          <div id="filters-sidebar___title__">More filters</div>
          <b-button variant="link" @click="hide">
            <font-awesome-icon
              :icon="['far', 'times']"/>
          </b-button>
        </template>
          <div v-if="filterTypeByArray(filterTypesAndStatus)">
            <JoinedSidebarSection
              :sections="filterTypeByArray(filterTypesAndStatus)"
              label='Type & Status'/>
          </div>
          <div v-if="filterTypeByArray(filterTypes)">
            <SidebarSection
              v-for="(filterType, filterTypeIndex) in filterTypeByArray(filterTypes)"
              :key="filterTypeIndex"
              :section="filterType"/>
          </div>
      </b-sidebar>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { mapGetters } from 'vuex'
import JoinedSidebarSection from '@/components/header/filters/sidebar/JoinedSidebarSection'
import SidebarSection from '@/components/header/filters/sidebar/SidebarSection'

export default {
  name: 'FilterSidebar',

  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
    disableFilters: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    JoinedSidebarSection,
    SidebarSection,
  },

  data: () => ({
    filterTypesAndStatus: [
      'garden_type',
      'open_status',
    ],
    filterTypes: [
      // 'garden_type',
      'garden_style',
      'seasonal_features',
      'about_visit',
    ],
    noCloseOnRouteChange: true,
  }),

  watch: {
    $route(value) {
      this.noCloseOnRouteChange = true;

      if (value.name !== 'List') {
        this.noCloseOnRouteChange = false;
      }
    },
  },

  computed: {
    ...mapGetters('filter', [
      'filterTypeByArray',
    ]),
    isIos() {
      return Capacitor.getPlatform() === 'ios';
    },
  },

  methods: {
    toggleMainScroll() {
      const sidebar = document.getElementById('filters-sidebar');
      const app = document.getElementById('app');

      if (sidebar.ariaHidden === 'true') {
        app.style.overflow = 'initial';
      } else {
        app.style.overflow = 'hidden';
      }
    },
  },
}
</script>

<style lang="scss">
  #filters-sidebar {
    z-index: 99;

    @media (max-width: 1200px) {
      background-color: $color-yellow-lighter !important;
    }

    #filters-sidebar___title__,
    .section-title {
      font-family: "NGS 2017", "Inter", sans-serif !important;
    }

    .b-sidebar-header {
      display: flex;
      justify-content: space-between;
      margin: 0 35px 0 20px;
      padding: 20px 0 10px;
      border-bottom: 1px solid $color-grey-light;

      #filters-sidebar___title__ {
        font-size: 32px;
      }
    }

    .b-sidebar-body {
      position: relative;

      .filter-section {
        margin: 10px 20px;
        padding: 0;
        padding-bottom: 1.125rem;
        border-bottom: 1px solid $color-grey-light;
        font-size: 1.125rem;
        color: $color-grey-dark;

        .section-title {
          font-size: 28px;
        }

        .option {
          font-size: 18px;
          color: $color-grey-main;
        }
      }
    }
  }

  .filters-sidebar-ios {
    padding-top: 44px !important;
  }
</style>
