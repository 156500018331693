import {
  clone,
  isEqual,
  merge,
  orderBy,
} from 'lodash'
import router from '@/router/index'
import { GARDEN_OPENING_TYPE_IDS_OPENING } from '@/constants'

export default {
  namespaced: true,

  state: {
    selectedSort: {
      label: 'By opening date',
      value: [
        (garden) => {
          if (garden?.openings?.length) {
            const date = new Date();
            const now = new Date(date.getFullYear(), date.getMonth(), date.getDate());

            // Filter out any past openings
            const gardenOpenings = garden.openings.filter((gardenOpening) => {
              // Safari does not support yyy-MM-dd date format
              const startDate = gardenOpening.start_date.replace(/-/g, '/');

              return new Date(startDate) >= now;
            });

            if (gardenOpenings.some((opening) => {
              return GARDEN_OPENING_TYPE_IDS_OPENING.includes(opening.garden_opening_type_id)
            })) {
              return 0;
            }
          }

          return 1;
        },
        (garden) => {
          if (garden?.openings?.length) {
            const date = new Date();
            const now = new Date(date.getFullYear(), date.getMonth(), date.getDate());

            // Filter out any past openings
            const gardenOpenings = garden.openings.filter((gardenOpening) => {
              // Safari does not support yyy-MM-dd date format
              const startDate = gardenOpening.start_date.replace(/-/g, '/');

              return new Date(startDate) >= now;
            });

            if (gardenOpenings.length) {
              return orderBy(gardenOpenings, [
                (opening) => {
                  if (GARDEN_OPENING_TYPE_IDS_OPENING.includes(opening.garden_opening_type_id)) {
                    return 0;
                  }

                  return 1;
                },
                'start_date',
              ])[0].start_date;
            }
          }

          return 0;
        },
      ],
      direction: ['asc', 'asc'],
    },
    defaultSort: {
      label: 'By opening date',
      value: [
        (garden) => {
          if (garden?.openings.length) {
            const date = new Date();
            const now = new Date(date.getFullYear(), date.getMonth(), date.getDate());

            // Filter out any past openings
            const gardenOpenings = garden.openings.filter((gardenOpening) => {
              // Safari does not support yyy-MM-dd date format
              const startDate = gardenOpening.start_date.replace(/-/g, '/');

              return new Date(startDate) >= now;
            });

            if (gardenOpenings.some((opening) => {
              return GARDEN_OPENING_TYPE_IDS_OPENING.includes(opening.garden_opening_type_id)
            })) {
              return 0;
            }
          }

          return 1;
        },
        (garden) => {
          if (garden?.openings.length) {
            const date = new Date();
            const now = new Date(date.getFullYear(), date.getMonth(), date.getDate());

            // Filter out any past openings
            const gardenOpenings = garden.openings.filter((gardenOpening) => {
              // Safari does not support yyy-MM-dd date format
              const startDate = gardenOpening.start_date.replace(/-/g, '/');

              return new Date(startDate) >= now;
            });

            if (gardenOpenings.length) {
              return orderBy(gardenOpenings, [
                (opening) => {
                  if (GARDEN_OPENING_TYPE_IDS_OPENING.includes(opening.garden_opening_type_id)) {
                    return 0;
                  }

                  return 1;
                },
                'start_date',
              ])[0].start_date;
            }
          }

          return 0;
        },
      ],
      direction: ['asc', 'asc'],
    },
    sortOptions: [
      {
        label: 'By garden name',
        value: ['name'],
        direction: ['asc'],
      },
      {
        label: 'By opening date',
        value: [
          (garden) => {
            if (garden?.openings.length) {
              const date = new Date();
              const now = new Date(date.getFullYear(), date.getMonth(), date.getDate());

              // Filter out any past openings
              const gardenOpenings = garden.openings.filter((gardenOpening) => {
                // Safari does not support yyy-MM-dd date format
                const startDate = gardenOpening.start_date.replace(/-/g, '/');

                return new Date(startDate) >= now;
              });

              if (gardenOpenings.some((opening) => {
                return GARDEN_OPENING_TYPE_IDS_OPENING.includes(opening.garden_opening_type_id)
              })) {
                return 0;
              }
            }

            return 1;
          },
          (garden) => {
            if (garden?.openings.length) {
              const date = new Date();
              const now = new Date(date.getFullYear(), date.getMonth(), date.getDate());

              // Filter out any past openings
              const gardenOpenings = garden.openings.filter((gardenOpening) => {
                // Safari does not support yyy-MM-dd date format
                const startDate = gardenOpening.start_date.replace(/-/g, '/');

                return new Date(startDate) >= now;
              });

              if (gardenOpenings.length) {
                return orderBy(gardenOpenings, [
                  (opening) => {
                    if (GARDEN_OPENING_TYPE_IDS_OPENING.includes(opening.garden_opening_type_id)) {
                      return 0;
                    }

                    return 1;
                  },
                  'start_date',
                ])[0].start_date;
              }
            }

            return 0;
          },
        ],
        direction: ['asc', 'asc'],
      },
    ],
  },

  mutations: {
    updateSort(state, sort) {
      if (state.selectedSort.label === sort.label) {
        state.selectedSort = state.defaultSort
      } else {
        state.selectedSort = sort
      }
    },
    clearState(state) {
      state.selectedSort = state.defaultSort
    },
  },

  actions: {
    clearState: ({ commit }) => new Promise((resolve) => {
      commit('clearState')
      resolve()
    }),
    updateSort: ({ commit, getters, rootGetters }, sort) => new Promise((resolve) => {
      commit('updateSort', sort)
      const filters = rootGetters['filter/selectedFiltersQuery']
      const searchBounds = rootGetters['map/searchBoundsQuery']
      const searchTerm = rootGetters['garden/searchTerm']
      const selectedSort = getters.selectedSortValue

      let urlQuery = clone(filters)

      if (searchTerm) {
        urlQuery = merge(urlQuery, { search_term: searchTerm })
      }
      if (searchBounds) {
        urlQuery = merge(urlQuery, { search_bounds: searchBounds })
      }

      if (selectedSort) {
        urlQuery = merge(urlQuery, { sort_by: selectedSort })
      }

      if (!isEqual(router.currentRoute.query, urlQuery)) {
        router.push({ name: 'List', query: urlQuery })
      }

      resolve()
    }),
  },

  getters: {
    selectedSort: (state) => state.selectedSort,
    selectedSortValue: (state) => state.selectedSort.label,
    sortOptions: (state) => state.sortOptions,
  },
}
