<template>
  <section
    class="filter-section">
    <div class="section-title">
      {{ section.label }}
    </div>
    <SectionFilter
      v-for="(filter, filterIndex) in section.options"
      :key="filterIndex"
      :tag="filter"
      :section="section"/>
  </section>
</template>

<script>
import SectionFilter from '@/components/header/filters/sidebar/SectionFilter'

export default {
  name: 'SidebarSection',

  components: {
    SectionFilter,
  },

  props: {
    section: Object,
  },
}
</script>

<style scoped lang="scss">

.filter-section {
  border-bottom: 1px solid $color-grey-lightest;
  font-size: 1.125rem;
  color: $color-grey-dark;
  padding: 1rem 2rem;
  .section-title {
    margin-bottom: 0.5rem;
  }

  // .option {
  //   cursor: pointer;
  //   color: $color-grey-main;
  //   margin-top: 0.5rem;
  // }
}
</style>
