import Vue from 'vue'
import * as Sentry from '@sentry/vue';

import { BootstrapVue } from 'bootstrap-vue'

import VCalendar from 'v-calendar';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faCar,
  faCheckSquare,
  faChevronCircleDown,
  faCircle as faCircleSolid,
  faDog,
  faFlower,
  faFlowerDaffodil as fasFlowerDaffodil,
  faMapMarker as fasMapMarker,
  faMugTea,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCircle as faCircleRegular,
  faClock,
  faDirections,
  faFlowerDaffodil as farFlowerDaffodil,
  faLocation,
  faMapMarker,
  faMapMarkerAlt,
  faSearch,
  faSearchMinus,
  faSlidersH,
  faTimes,
  faTree,
  faTrees,
  faWheelchair,
  faWifi,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronRight as faChevronRightLight,
  faChevronLeft as faChevronLeftLight,
  faCircle as faCircleLight,
  faInfoCircle,
  faList,
  faMap,
  faSquare,
} from '@fortawesome/pro-light-svg-icons';
import {
  faFacebookF,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faFlower as fadFlower,
  faSpinner as fadSpinner,
} from '@fortawesome/pro-duotone-svg-icons';

import App from './App'
import router from './router'
import store from './store'

import '@/assets/scss/main.scss'

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  import('@/services/gtm').then();
}

library.add(
  faArrowLeft,
  faArrowRight,
  faCar,
  faCheck,
  faCheckSquare,
  faChevronCircleDown,
  faChevronDown,
  faChevronRight,
  faChevronRightLight,
  faChevronLeft,
  faChevronLeftLight,
  faCircleLight,
  faCircleRegular,
  faCircleSolid,
  faClock,
  faDog,
  faDirections,
  faFacebookF,
  faFlower,
  fadFlower,
  farFlowerDaffodil,
  fasFlowerDaffodil,
  faInfoCircle,
  faList,
  faLocation,
  faMap,
  fasMapMarker,
  faMapMarker,
  faMapMarkerAlt,
  faMugTea,
  faSearch,
  faSearchMinus,
  faSlidersH,
  fadSpinner,
  faSquare,
  faTimes,
  faTree,
  faTrees,
  faTwitter,
  faWheelchair,
  faWifi,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue)

Vue.use(require('vue-moment'));

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Sentry.init({
  Vue,
  dsn: 'https://206516623c2009579d2e4bb1bc0d14f0@sentry.totallydev.com/3',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/ngs-api\.qa\.tclstaging\.com/,
    /^https:\/\/ngs-api\.uat\.tclstaging\.com/,
    /^https:\/\/api\.findagarden\.ngs\.org\.uk/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
