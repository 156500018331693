<template>
  <div class="filter-switch-wrapper">
    <b-button-group>
      <b-button
        class="filter-button"
        variant="outline-secondary"
        v-for="(option, optionIndex) in section.options"
        :key="optionIndex"
        :disabled="disableFilters"
        :pressed="filterSelected(section.filter, option.value)"
        @click="selectTag(option)">{{ option.label }}</b-button>
    </b-button-group>
    </div>
</template>

<script>
import { includes } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FilterSwitch',

  props: {
    section: Object,
    filterSection: String,
    showLeftIcon: {
      type: Boolean,
      default: false,
    },
    selectedAsName: {
      type: Boolean,
      default: false,
    },
    disableFilters: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    radioIcons: {
      unSelected: ['fal', 'square'],
      selected: ['fas', 'check-square'],
    },
  }),

  computed: {
    ...mapGetters('filter', [
      'filterSelected',
      'sectionFilterSelected',
    ]),
  },

  methods: {
    ...mapActions('filter', [
      'addFilter',
    ]),

    async selectTag(option) {
      this.optionValue = option.label
      let localOptionValue = option.value

      if (includes(['checkbox', 'buttons'], this.section.display_type)) {
        localOptionValue = [option.value]
      }

      await this.addFilter({ filter: this.section.filter, type: this.section.display_type, options: localOptionValue })
    },
  },
}
</script>

<style scoped lang="scss">
.filter-switch-wrapper {
  margin-right: 0.5rem;

  .btn-group {
    min-width: 220px;
  }
}
.filter-dropdown {
  min-width: 16rem;
}
.filter-range {
  min-width: 19rem;
  .filter-range-option-button {
    &:not(:last-child) {
      margin-right: 0.539rem;
    }
  }
}
.filter-list-options-wrapper {
  max-height: 9.388rem;
  overflow-y: auto;
}
</style>
