import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((allModules, [name, module]) => {
    allModules[name] = module.default
    return allModules
  }, {})

const dataState = createPersistedState({
  paths: [
    'filter.selectedFilters',
    'filter.dynamicFilterSections',
    'garden.selectedSearchType',
    'garden.searchTerm',
    'garden.searchModalShown',
    'location.selectedLocation',
    'map.searchBounds',
    'map.geoBoundingBox',
  ],
});

const store = new Vuex.Store({
  state: {
  },
  modules,
  plugins: [dataState],
})

export default store
