import { clone } from 'lodash'

export default {
  namespaced: true,

  state: {
    markers: [],
    mapBounds: null,
    searchBounds: null,
    geoBoundingBox: null,
    routeSetup: true,
    loadingMap: false,
  },

  mutations: {
    addMarker(state, marker) {
      state.markers.push(marker)
    },
    setMarkers(state, markers) {
      state.markers = markers
    },
    addGeoBoundingBox(state, geoBoundingBox) {
      state.geoBoundingBox = clone(geoBoundingBox)
    },
    addMapBounds(state, mapBounds) {
      state.mapBounds = clone(mapBounds)
    },
    addSearchBounds(state, searchBounds) {
      state.searchBounds = clone(searchBounds)
    },
    updateLoadingMap(state, isLoading) {
      state.loadingMap = clone(isLoading)
    },
    toggleRouteSetup(state, setup) {
      state.routeSetup = setup
    },
    clearGeoBoundingBox(state) {
      state.geoBoundingBox = null
    },
    clearSearchBounds(state) {
      state.searchBounds = null
    },
    clearState(state) {
      state.markers = []
      state.mapBounds = null
      state.searchBounds = null
      state.geoBoundingBox = null
      state.loadingMap = false
    },
  },

  actions: {
    clearState: ({ commit }) => new Promise((resolve) => {
      commit('clearState')
      resolve()
    }),

    updateLoadingMap: ({ commit }, isLoading) => new Promise((resolve, reject) => {
      commit('updateLoadingMap', isLoading)
      resolve()
    }),

    toggleRouteSetup: ({ commit }, setup) => new Promise((resolve) => {
      commit('toggleRouteSetup', setup)
      resolve()
    }),

    setMarkers: async ({ commit, dispatch }, markers) => {
      await commit('setMarkers', markers)
      await dispatch('updateLoadingMap', false)
    },
    addMapBounds: ({ commit }, bounds) => new Promise((resolve) => {
      commit('addMapBounds', bounds)
      resolve()
    }),

    addAndFilterBounds: ({
      commit,
      dispatch,
      state,
      rootGetters,
    }, bounds) => new Promise((resolve) => {
      if (state.routeSetup) {
        commit('toggleRouteSetup', false)
        commit('addMapBounds', bounds)
        const searchTerm = rootGetters['garden/searchTerm']
        const searchTypeValue = rootGetters['garden/searchTypeValue']
        if (searchTerm && searchTypeValue === 'location') {
          // commit('clearSearchBounds')
          // dispatch('garden/clearSearch', [{}], { root: true })
        }

        dispatch('addGeoBoundingBox', bounds)

        dispatch('filter/addBoundsFilter', bounds, { root: true })
      }
      resolve()
    }),

    addSearchBounds: ({ commit, dispatch }, bounds) => new Promise((resolve) => {
      commit('addSearchBounds', bounds)

      dispatch('addGeoBoundingBox', bounds)
      resolve()
    }),

    clearSearchBounds: ({ commit }) => new Promise((resolve) => {
      commit('clearSearchBounds')
      resolve()
    }),

    addGeoBoundingBox: ({ commit }, geoBoundingBox) => new Promise((resolve) => {
      const boundingBox = {
        top_right: {
          lat: Number(geoBoundingBox?.top_right?.lat),
          lng: Number(geoBoundingBox?.top_right?.lng),
        },
        bottom_right: {
          lat: Number(geoBoundingBox?.bottom_right?.lat),
          lng: Number(geoBoundingBox?.bottom_right?.lng),
        },
        bottom_left: {
          lat: Number(geoBoundingBox?.bottom_left?.lat),
          lng: Number(geoBoundingBox?.bottom_left?.lng),
        },
        top_left: {
          lat: Number(geoBoundingBox?.top_left?.lat),
          lng: Number(geoBoundingBox?.top_left?.lng),
        },
      };

      commit('addGeoBoundingBox', boundingBox)
      resolve()
    }),
  },

  getters: {
    markers: (state) => state.markers,
    mapBounds: (state) => state.mapBounds,
    searchBounds: (state) => state.searchBounds,
    searchBoundsQuery: (state) => {
      return state.searchBounds ? `${state.searchBounds.top_right.lat},${state.searchBounds.top_right.lng},${state.searchBounds.bottom_left.lat},${state.searchBounds.bottom_left.lng}` : null
    },
    geoBoundingBox: (state) => state.geoBoundingBox,
    routeSetup: (state) => state.routeSetup,
    loadingMap: (state) => state.loadingMap,
  },
}
