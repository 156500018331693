<template>
  <div
    class="option"
    :class="{'joined' : joined }"
    @click="selectTag()">
    <div
      class="option-square"
      :class="{ 'selected' : selected }">
      <font-awesome-icon
        class="radio-button-icon"
        :icon="radioIcons.selected"/>
    </div>

    {{ tag.label }}
  </div>
</template>

<script>
import { includes } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionFilter',

  props: {
    tag: Object,
    section: Object,
    joined: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    radioIcons: {
      selected: ['far', 'check'],
    },
  }),

  computed: {
    ...mapGetters('filter', [
      'selectedFilters',
      'filterSelected',
    ]),

    selected() {
      return this.filterSelected(this.section.filter, this.tag.value)
    },
  },

  methods: {
    ...mapActions('filter', [
      'addFilter',
    ]),

    selectTag() {
      let localOptionValue = [this.tag.value]
      if (includes(['toggle'], this.section.display_type)) {
        localOptionValue = this.tag.value
      }
      this.addFilter({ filter: this.section.filter, type: this.section.display_type, options: localOptionValue })
    },
  },
}
</script>

<style scoped lang="scss">

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $color-grey-main;
  margin-top: 0.313rem;
  &:not(.joined) {
    &:first-child {
      margin-top: 0.813rem;
    }
  }

  .option-square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.313rem;
    height: 1.313rem;
    border: 1px solid $color-grey-lightest;
    background-color: $color-white-main;
    border-radius: 5px;
    margin-right: 0.688rem;

    &.selected {
      background-color: $color-yellow-main;
      border-color: $color-yellow-main;
    }

    .radio-button-icon {
      font-size: 12px;
      color: #ffffff;
    }
  }
}
</style>
