<template>
  <div id="header" ref="mainHeader">
    <div id="top-wrapper">
      <div class="desktop-header d-none d-lg-flex">
        <a href="/" class="logo-wrapper">
          <img
            class="logo"
            alt="National Garden Scheme logo"
            src="@/assets/images/logo.png">
        </a>
        <div class="find-a-garden-banner">
          <div class="title">
            <div class="heading ngs-font">Find a Garden</div>
          </div>
        </div>
        <div class="back-to-ngs-link-wrapper">
          <b-button
            class="back-to-ngs-link"
            href="https://ngs.org.uk"
            variant="link"
          >
            <h4>Back to homepage</h4>
          </b-button>
        </div>
      </div>
      <div class="mobile-header d-lg-none d-flex">
        <a href="/">
          <img
            class="logo"
            alt="National Garden Scheme logo"
            src="@/assets/images/logo.png">
        </a>
        <div class="back-to-ngs-link-wrapper">
          <b-button
            class="back-to-ngs-link"
            href="https://ngs.org.uk"
            variant="link"
          >
            <h4 class="m-0">Back to homepage</h4>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CleanHeader',

  mounted() {
    this.$nextTick(function () {
      if (!this.dynamicFilterSections.length) {
        this.fetchAllFiters()
      }
    })
  },

  computed: {
    ...mapGetters('filter', [
      'dynamicFilterSections',
    ]),
  },

  methods: {
    ...mapActions('filter', [
      'fetchAllFiters',
    ]),
  },
}

</script>

<style lang="scss" scoped>
  #header {
    #top-wrapper {
      .desktop-header {
        justify-content: space-between !important;

        .logo-wrapper,
        .back-to-ngs-link-wrapper {
          width: 175px;
        }

        .back-to-ngs-link-wrapper {
          margin-left: unset;
        }
      }
    }
  }
</style>

<style lang="scss">
#header {
  #top-wrapper {
    .heading {
      font-size: 60px;
      line-height: 60px;
    }

    .desktop-header {
      align-items: center;
      padding: 0.5rem;

      .logo {
        width: 81px;
      }
      .back-to-ngs-link-wrapper {
        margin-left: auto;
        text-align: right;

        .back-to-ngs-link {
          font-size: 0.8125rem;
        }
      }
    }

    .mobile-header {
      padding: 8px 10px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .search-result-button {
        width: 203px;
      }

      .filter-sidebar {
        .filter-sidebar-wrapper {
          .btn {
            border: none;
            background: none;
          }
        }
      }

      .logo {
        width: 47px;
      }
    }
  }
}
</style>
