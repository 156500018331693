<template>
  <div class="filter-button-group-size-wrapper">
    <b-dropdown
      class="force-header-position"
      toggle-class="filter-button"
      variant="outline-secondary"
      :ref="section.filter"
      dropdown
      no-caret
      :disabled="disableFilters || !byArrangementSelected"
    >
      <template #button-content>
        <font-awesome-icon
          class="left-icon"
          v-if="showLeftIcon && section.icon"
          :icon="section.icon"/>
        {{ buttonLabel }}
        <font-awesome-icon class="right-icon" :icon="['far', 'chevron-down']"/>
      </template>
      <div v-if="section.display_type === 'radio'"
          class="filter-dropdown">
        <b-dropdown-item-button
          class="filter-option"
          v-for="(option, optionIndex) in section.options"
          :key="optionIndex"
          @click="selectTag(option)">
          <font-awesome-icon
            class="radio-button-icon"
            :icon="filterSelected(section.filter, option.value) ? radioIcons.selected : radioIcons.unSelected"/>
          {{ option.label }}
        </b-dropdown-item-button>
      </div>
      <div v-if="section.display_type === 'multi_button'" class="filter-range">
        <b-dropdown-text class="filter-label">
          <h3 class="m-0">{{ section.label }}</h3>
          <button class="dropdown-close" @click="closeDropdown()">
            <font-awesome-icon :icon="['far','times']" />
          </button>
          <p class="sub-label">How many people are in your group?</p>
        </b-dropdown-text>
        <div class="dd-items">
          <b-dropdown-text
            class="filter-range-options">
            <b-button
                class="filter-range-option-button"
                v-for="(option, optionIndex) in section.options"
                :key="optionIndex"
                :pressed="filterSelected(section.filter, option.value)"
                @click="selectTag(option)"
                variant="outline-secondary"
                size="sm"
            >
              {{ option.label }}
            </b-button>
          </b-dropdown-text>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { find, includes } from 'lodash'

export default {
  name: 'FilterButtonGroupSize',

  props: {
    section: Object,
    filterSection: String,
    showLeftIcon: {
      type: Boolean,
      default: false,
    },
    selectedAsName: {
      type: Boolean,
      default: false,
    },
    disableFilters: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    radioIcons: {
      unSelected: ['fal', 'square'],
      selected: ['fas', 'check-square'],
    },
    optionValue: null,
    localOptionValue: null,
  }),

  computed: {
    ...mapGetters('filter', [
      'filterSelected',
      'sectionFilterSelected',
    ]),

    buttonLabel() {
      return this.localOptionValue || this.section.label;
    },

    currentlySelected() {
      return this.section.options.find((option) => this.sectionFilterSelected(this.section.filter) === option.value);
    },

    byArrangementSelected() {
      return this.sectionFilterSelected('opening_type') === 'by-arrangement'
    },
  },

  watch: {
    byArrangementSelected(newValue, oldValue) {
      if (oldValue && !newValue && this.localOptionValue) {
        this.selectTag(find(this.section.options, ['value', this.localOptionValue]))
      }
    },
  },

  created() {
    this.localOptionValue = this.currentlySelected?.value;
  },

  methods: {
    ...mapActions('filter', [
      'addFilter',
    ]),

    selectedDefault() {
      if (includes(['radio'], this.section.display_type) && !this.sectionFilterSelected(this.section.filter).length) {
        this.selectTag(this.section.options[0])
      }
    },

    async selectTag(option) {
      this.optionValue = option.label;

      if (option.label === this.localOptionValue) {
        this.localOptionValue = null;
      } else {
        this.localOptionValue = option.label;
      }

      let localOptionValue = option.value;

      if (includes(['checkbox', 'buttons'], this.section.display_type)) {
        localOptionValue = [option.value]
      }
      await this.addFilter({ filter: this.section.filter, type: this.section.display_type, options: localOptionValue })
    },
    closeDropdown() {
      this.$refs[this.section.filter].$el.getElementsByClassName('dropdown-toggle')[0].click();
    },
  },
}
</script>
<style lang="scss">
  .filter-button-group-size-wrapper {
    .dropdown-menu {
      .b-dropdown-text {
        font-size: 20px;
        font-family: 'NGS 2017';
      }

      .filter-range {
        .filter-label {
          .sub-label {
            font-size: 16px;
            margin: 0;

            @media (max-width: 1200px) {
              font-size: 20px;
            }
          }
        }
      }

      .filter-dropdown,
      .filter-range-options * {
        font-size: 14px !important;
      }

      .btn {
        padding: 0;
        height: 35px;
      }
    }

    .btn {
      &.dropdown-toggle {
        width: 113px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 1200px) {
      .btn-group,
      .btn-group-vertical,
      .dropdown {
        position: initial;
      }

      .dropdown-menu {
        top: -40px !important;
      }
    }
  }
</style>

<style scoped lang="scss">
  .filter-dropdown {
    min-width: 16rem;
  }
  .filter-range {
    min-width: 19rem;
    .filter-range-option-button {
      &:not(:last-child) {
        margin-right: 0.539rem;
      }
    }
  }
  .filter-list-options-wrapper {
    max-height: 9.388rem;
    overflow-y: auto;
  }
</style>
