import {
  each,
  find,
  isArray,
  isEqual,
  includes,
  merge,
  replace,
  split,
} from 'lodash'
import { Capacitor } from '@capacitor/core'
import { getFullBoundsFromArray } from '@/services/location'
import store from '@/store/index'

async function clearState() {
  await store.dispatch('filter/clearFilters', [{}], { root: true })
  await store.dispatch('location/clearSelectedLocation', [{}], { root: true })
  await store.dispatch('garden/clearState', [{}], { root: true })
  await store.dispatch('map/clearState', [{}], { root: true })
}

const routes = [
  Capacitor.getPlatform() === 'web' ? {
    path: '/',
    name: 'Search',
    //  route level code-splitting
    // this generates a separate chunk (methodInformation.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "list" */ '@/views/pages/List'),
    meta: {
      nav: true,
      header: 'FilterHeader',
    },
    async beforeEnter(to, from, next) {
      await clearState();

      next();
    },
  } : {
    path: '/',
    name: 'AppHomeScreen',
    //  route level code-splitting
    // this generates a separate chunk (methodInformation.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "appHomeScreen" */ '@/views/pages/AppHomeScreen'),
    meta: {},
    async beforeEnter(to, from, next) {
      await clearState();

      next();
    },
  },
  {
    path: '/garden-list',
    name: 'List',
    component: () => import(/* webpackChunkName: "list" */ '@/views/pages/List'),
    meta: {
      nav: true,
      header: 'FilterHeader',
    },
    async beforeEnter(to, from, next) {
      if (!from?.name) {
        await clearState();
      }

      if (to.query) {
        await store.dispatch('map/toggleRouteSetup', false, { root: true })

        // Get all of the currently Set filters
        const selectedFiltersQuery = store.getters['filter/selectedFiltersQuery']
        const selectedFiltersQueryCheck = store.getters['filter/selectedFiltersQueryCheck']
        const searchTerm = store.getters['garden/searchTerm']
        const selectedSearchType = store.getters['garden/selectedSearchType']
        const searchBounds = store.getters['map/searchBoundsQuery']
        const selectedSort = store.getters['sort/selectedSortValue']

        // Build up a query param from the currently set filters in order to check if they match the url params
        let queryFilters = {}
        if (searchTerm) {
          queryFilters = merge(queryFilters, { search_term: searchTerm })
        }
        if (searchBounds) {
          queryFilters = merge(queryFilters, { search_bounds: searchBounds })
        }
        if (selectedSort) {
          queryFilters = merge(queryFilters, { sort_by: selectedSort })
        }

        if (selectedFiltersQuery) {
          queryFilters = merge(queryFilters, selectedFiltersQueryCheck)
        }

        // Check if the current params match the url params
        if (!isEqual(to.query, queryFilters)) {
          // If not equal then grab the dynamicFilterSections and fetch all the filters from the api filters and reset the current set filters
          let dynamicFilterSections = store.getters['filter/dynamicFilterSections']
          try {
            if (!dynamicFilterSections) {
              await store.dispatch('filter/fetchAllFiters', [{}], { root: true })
              dynamicFilterSections = store.getters['filter/dynamicFilterSections']
              await store.dispatch('filter/resetFilters', [{}], { root: true })
            }
          } catch (e) {
            console.log('Could not fetch filters')
          }

          // Run through all of the url filters and set them as current set filters
          each(to.query, function (urlQueryFilter, urlQueryFilterKey) {
            let isntSearch = false
            if (!includes(['search_term', 'search_bounds', 'sort_by'], urlQueryFilterKey)) {
              isntSearch = true
            }

            if (isntSearch) {
              const filterKey = replace(replace(urlQueryFilterKey, 'filter[', ''), ']', '')

              // Set the filters that are arrays
              if (isArray(urlQueryFilter)) {
                each(urlQueryFilter, function (itemToAdd) {
                  // FILTERS ONLY GET SET IF THEY MATCH ONE OF THE DYNAMIC FILTERS FETCHED FROM THE API
                  const matchingDynamicFilterSection = dynamicFilterSections ? find(dynamicFilterSections, function (dynamicFilterSection) {
                    return dynamicFilterSection.filter === filterKey && find(dynamicFilterSection.options, ['value', itemToAdd])
                  }) : null
                  store.dispatch('filter/updateTag', { filter: filterKey, type: matchingDynamicFilterSection.display_type, options: [itemToAdd] }, { root: true })
                })
              }

              // Set the filters that are NOT arrays
              if (!isArray(urlQueryFilter)) {
                const matchingDynamicFilterSection = dynamicFilterSections ? find(dynamicFilterSections, function (dynamicFilterSection) {
                  return dynamicFilterSection.filter === filterKey && find(dynamicFilterSection.options, ['value', urlQueryFilter])
                }) : null

                // FILTERS ONLY GET SET IF THEY MATCH ONE OF THE DYNAMIC FILTERS FETCHED FROM THE API (except for geo_bounding_box, date_open_from & date_open_to)
                if (matchingDynamicFilterSection) {
                  if (includes(['checkbox', 'buttons'], matchingDynamicFilterSection.display_type)) {
                    store.dispatch('filter/updateTag', { filter: filterKey, type: matchingDynamicFilterSection.display_type, options: [urlQueryFilter] }, { root: true })
                  }

                  if (includes(['radio', 'defaulted_toggle', 'multi_button'], matchingDynamicFilterSection.display_type)) {
                    store.dispatch(
                      'filter/updateTag',
                      {
                        filter: filterKey,
                        type: matchingDynamicFilterSection.display_type,
                        options: urlQueryFilter,
                        initial: true,
                      },
                      { root: true },
                    )
                  }
                } else {
                  if (filterKey === 'geo_bounding_box') {
                    store.dispatch('filter/updateTag', { filter: filterKey, type: 'text', options: urlQueryFilter }, { root: true })
                  }
                  if (filterKey === 'date_open_from' && to.query['filter[date_open_to]']) {
                    const toUrlQueryfilter = to.query['filter[date_open_to]']
                    store.dispatch('filter/updateTag',
                      {
                        filter: 'days_open',
                        type: 'date_range',
                        options: {
                          date_open_from: urlQueryFilter,
                          date_open_to: toUrlQueryfilter,
                        },
                      }, { root: true })
                  }
                }
              }
            }
          })

          if (to.query.search_term || to.query.search_bounds) {
            if (to.query.search_term) {
              if (to.query['filter[garden_name]']) {
                if (to.query['filter[garden_name]'] === to.query.search_term) {
                  if (selectedSearchType.value !== 'garden') {
                    store.dispatch('garden/updateSearchType', store.getters['garden/gardenSearchType'], { root: true })
                  }
                  store.dispatch('filter/updateTag', { filter: 'garden', type: 'text', options: to.query.search_term }, { root: true })
                } else {
                  store.dispatch('filter/updateTag', { filter: 'garden_name', type: 'text', options: to.query['filter[garden_name]'] }, { root: true })
                }
              }
              store.dispatch('garden/updateSearchTerm', to.query.search_term, { root: true })
            }
            store.dispatch('garden/clearSearchResults', [{}], { root: true })

            if (to.query.search_bounds) {
              if (selectedSearchType.value !== 'location') {
                store.dispatch('garden/updateSearchType', store.getters['garden/locationSearchType'], { root: true })
              }
              const searchBoundsLngLat = split(to.query.search_bounds, ',')
              const bounds = getFullBoundsFromArray(searchBoundsLngLat)

              store.dispatch('map/addSearchBounds', bounds, { root: true })

              const boundsFilter = {
                filter: 'geo_bounding_box',
                type: 'text',
                options: to.query.search_bounds,
              }

              store.dispatch('filter/updateTag', boundsFilter, { root: true })
            }
          }

          // SORTING
          if (to.query.sort_by) {
            if (to.query.sort_by !== selectedSort) {
              const sortByOption = find(store.getters['sort/sortOptions'], ['label', to.query.sort_by])
              store.dispatch('sort/updateSort', sortByOption, { root: true })
            }
          } else {
            if (selectedSort) {
              store.dispatch('sort/clearState', { root: true })
            }
          }
        }
        store.dispatch('map/toggleRouteSetup', true, { root: true })
      }

      next();
    },
  },
  {
    path: '/garden/:gardenId/:gardenName',
    name: 'Garden',
    //  route level code-splitting
    // this generates a separate chunk (methodInformation.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "garden" */ '@/views/pages/Garden'),
    meta: {
      nav: true,
      header: 'FilterHeader',
    },
  },
]

export default routes
