import { filter, find } from 'lodash'

export default {
  namespaced: true,

  state: {
    existingTags: [
      {
        name: 'Accommodation available',
        icon: 'bnb',
      },
      {
        name: 'Refreshments',
        icon: 'teas',
      },
      {
        name: 'Card payments accepted',
        icon: 'cashless',
      },
      {
        name: 'Plant Heritage National Plant Collection',
        icon: 'nccpg',
      },
      {
        name: 'Wheelchair access',
        icon: 'wheelchair',
      },
      {
        name: 'Dogs on short leads welcome',
        icon: 'dogs',
      },
      {
        name: 'Plants for sale',
        icon: 'plants',
      },
      {
        name: 'Picnics',
        icon: 'picnics',
      },
      {
        name: 'Coaches welcome',
        icon: 'coaches',
      },
      {
        name: 'Flowers',
        icon: 'flowers',
      },
    ],
  },

  getters: {
    findIconByTag: (state) => (tag) => {
      return find(state.existingTags, function (existingTag) {
        return existingTag.icon === tag
      }) ?? null
    },

    localTags: (state) => (tags) => {
      return filter(state.existingTags, function (existingTag) {
        return find(tags, function (localTag) {
          return localTag === existingTag.icon
        });
      });
    },
  },
}
