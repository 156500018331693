import { clone } from 'lodash'
import GoogleMapsApiLoader from 'google-maps-api-loader'
import { Capacitor } from '@capacitor/core';
import GEO_LOCATION from '@/services/geolocation'

async function getCoordinates() {
  const platform = Capacitor.getPlatform();

  if (platform === 'web') {
    return new Promise(function (resolve, reject) {
      if (GEO_LOCATION) {
        GEO_LOCATION.getCurrentPosition(resolve, reject);
      }
    });
  }

  const position = await GEO_LOCATION.getCurrentPosition();
  return position;
}

const watchLocation = (setWatchLocation) => {
  const platform = Capacitor.getPlatform();

  if (platform === 'web') {
    GEO_LOCATION.watchPosition((position) => {
      const coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }

      setWatchLocation(coords)
    })
    return;
  }

  GEO_LOCATION.watchPosition({
    enableHighAccuracy: false,
    timeout: 30000,
    maximumAge: 0,
  }, (position) => {
    const coords = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }

    setWatchLocation(coords)
  })
}
// const getAddressFromLocation = async (latlng) => {
//   return mapApi.address({ latlng }, (response) => {
//     const postalCode = find(response.data.results, function (result) {
//       return result.types.length === 1 && result.types[0] === 'postal_code'
//     })
//     return postalCode ?? response.data.results[0]
//   })
// }

const getLocation = async () => {
  const position = await getCoordinates();

  const { latitude: lat, longitude: lng } = position.coords

  return {
    lat,
    lng,
  }
}

const getBounds = async (location, radius) => {
  const google = await GoogleMapsApiLoader({
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  })

  const bounds = await new google.maps.Circle({ center: location, radius }).getBounds()
  const northeast = bounds.getNorthEast()
  const southwest = bounds.getSouthWest()

  return {
    northeast: {
      lat: northeast.lat(),
      lng: northeast.lng(),
    },
    southwest: {
      lat: southwest.lat(),
      lng: southwest.lng(),
    },
  }
}

const getCenterPoint = async (location) => {
  const google = await GoogleMapsApiLoader({
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  })

  const topLeft = await new google.maps.LatLng(location.northeast.lat, location.northeast.lng)
  const bottomRight = await new google.maps.LatLng(location.southwest.lat, location.southwest.lng)

  const centerBounds = new google.maps.LatLngBounds()
  centerBounds.extend(topLeft)
  centerBounds.extend(bottomRight)

  return {
    lat: centerBounds.getCenter().lat(),
    lng: centerBounds.getCenter().lng(),
  }
}

const getLocationAndBounds = async (radius) => {
  const location = await getLocation()
  const bounds = await getBounds(location, radius)

  return {
    location,
    bounds,
  }
}

function getLatLngFromFunctionOrObject(bounds) {
  const convertedBounds = clone(bounds)
  let { lat, lng = null, lon = null } = convertedBounds
  if (typeof lat === 'function') {
    lat = bounds.lat()
  }
  if (typeof lng === 'function') {
    lng = bounds.lng()
  }
  if (typeof lon === 'function') {
    lon = bounds.lon()
  }

  lng = lng ?? lon

  return {
    lat,
    lng,
  }
}

const getFullBoundsFromNESW = ({ northeast, southwest }) => {
  northeast = getLatLngFromFunctionOrObject(northeast)
  southwest = getLatLngFromFunctionOrObject(southwest)

  return {
    top_right: {
      lat: northeast.lat,
      lng: northeast.lng,
    },
    bottom_right: {
      lat: southwest.lat,
      lng: northeast.lng,
    },
    bottom_left: {
      lat: southwest.lat,
      lng: southwest.lng,
    },
    top_left: {
      lat: northeast.lat,
      lng: southwest.lng,
    },
  }
}

const getFullBoundsFromTLBR = ({ topleft, bottomright }) => {
  const northwest = getLatLngFromFunctionOrObject(topleft)
  const southeast = getLatLngFromFunctionOrObject(bottomright)

  return {
    top_right: {
      lat: northwest.lat,
      lng: southeast.lng,
    },
    bottom_right: {
      lat: southeast.lat,
      lng: southeast.lng,
    },
    bottom_left: {
      lat: southeast.lat,
      lng: northwest.lng,
    },
    top_left: {
      lat: northwest.lat,
      lng: northwest.lng,
    },
  }
}

const getFullBoundsFromArray = (boundsArray) => {
  let northeast = {
    lat: boundsArray[0],
    lng: boundsArray[1],
  }
  let southwest = {
    lat: boundsArray[2],
    lng: boundsArray[3],
  }
  northeast = getLatLngFromFunctionOrObject(northeast)
  southwest = getLatLngFromFunctionOrObject(southwest)

  return {
    top_right: {
      lat: northeast.lat,
      lng: northeast.lng,
    },
    bottom_right: {
      lat: southwest.lat,
      lng: northeast.lng,
    },
    bottom_left: {
      lat: southwest.lat,
      lng: southwest.lng,
    },
    top_left: {
      lat: northeast.lat,
      lng: southwest.lng,
    },
  }
}

export {
  getLocation,
  watchLocation,
  getBounds,
  getCenterPoint,
  getLocationAndBounds,
  getFullBoundsFromNESW,
  getFullBoundsFromTLBR,
  getFullBoundsFromArray,
}
