<template>
  <div class="filter-name-wrapper">
    <b-form-input
      class="filter-name-input"
      type="text"
      v-model="name"
      :disabled="disableFilters || isSearchPage || searchTypeValue === 'garden'"
      placeholder="Garden name"></b-form-input>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isString } from 'lodash'

export default {
  name: 'FilterName',

  props: {
    disableFilters: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    sectionFilter: 'garden_name',
    displayType: 'text',
  }),

  computed: {
    ...mapGetters('filter', [
      'sectionFilterSelected',
    ]),

    ...mapGetters('garden', [
      'searchTypeValue',
    ]),

    isSearchPage() {
      return this.$route.name === 'Search'
    },

    name: {
      get() {
        return isString(this.sectionFilterSelected(this.sectionFilter)) ? this.sectionFilterSelected(this.sectionFilter) : null
      },
      set(value) {
        this.addFilter({ filter: this.sectionFilter, type: this.displayType, options: value })
      },
    },
  },

  methods: {
    ...mapActions('filter', [
      'addFilter',
    ]),
  },
}
</script>

<style scoped lang="scss">
.filter-name-wrapper {
  margin-right: 0.5rem;

  .filter-name-input {
    min-width: 120px;
    border-color: $color-grey-dark;
    height: 34px;
  }
}
.filter-dropdown {
  min-width: 16rem;
}
.filter-range {
  min-width: 19rem;
  .filter-range-option-button {
    &:not(:last-child) {
      margin-right: 0.539rem;
    }
  }
}
.filter-list-options-wrapper {
  max-height: 9.388rem;
  overflow-y: auto;
}
</style>
