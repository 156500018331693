<template>
    <div class="layout-wrapper container-fluid"
    :class="{ 'is-search-page': isSearchPage }">
      <component
        :is="pageHeader"/>
      <router-view />
      <b-modal
        id="search-modal"
        hide-backdrop
        hide-footer
        content-class="shadow">
        <template #modal-title>
          <font-awesome-icon :icon="['far', 'flower-daffodil']"/> Looking for a specific garden name?
        </template>
        <p>
          If you already know the name of the garden, use the dropdown to search by its name or click the button below...
        </p>
        <b-button
          variant="primary"
          @click="setSearchTypeToGarden()">Search by garden name</b-button>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const requireContext = require.context('@/components/header/headers', false, /.*\.vue$/);

const headers = requireContext.keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file).default])
  .reduce((allLayouts, [name, layout]) => {
    allLayouts[name] = layout;
    return allLayouts;
  }, {});

export default {
  name: 'DefaultLayout',

  components: {
    ...headers,
  },

  computed: {
    ...mapGetters('garden', [
      'gardenSearchType',
    ]),

    pageHeader() {
      return this.$route.meta.header;
    },
    isSearchPage() {
      return this.$route.name === 'Search'
    },
  },
  methods: {
    ...mapActions('garden', [
      'updateSearchType',
      'search',
    ]),

    async setSearchTypeToGarden() {
      await this.updateSearchType(this.gardenSearchType)
      this.search(this.$refs.$children)

      this.$bvModal.hide('search-modal')
    },
  },
}
</script>

<style scoped lang="scss">
.layout-wrapper {
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
  max-height: 100vh;

  @include media-breakpoint-up(lg) {
    overflow: hidden;
  }
}
</style>
<style lang="scss">
#search-modal {
  .modal-dialog {
    margin-left: 6.05rem;
    margin-top: 5.05rem;
    max-width: 350px;

    .modal-header {
      border-bottom: none;
      padding-bottom: 0px;

      .modal-title {
        font-weight: 600;
      }
    }

    .modal-body {
      font-family: "NGS 2017", "Inter", sans-serif !important;
      font-weight: 200;
      padding-top: 0px;
    }
  }
}
</style>
