<template>
  <div id="filter-buttons-wrapper" class="filter-buttons-wrapper">
    <div class="filters">
      <div class="filter-buttons filter-buttons-mobile">
        <b-dropdown
          variant="outline-secondary"
          class="search-types-dropdown radial-distance-dropdown d-lg-none"
          :disabled="searchTypeValue === 'garden'"
          no-caret
          title="Distance">
          <template #button-content>
            <font-awesome-icon :icon="['far', 'directions']"/>
            <span class="d-sm-inline">
              {{ selectedRadius.name }}
            </span>
            <font-awesome-icon class="right-icon radial-distance-dropdown--chevron" :icon="['far', 'chevron-down']"/>
          </template>
          <b-dropdown-item-button
            v-for="(option, optionIndex) in radiusOtions"
            :key="optionIndex"
            class="radial-distance-dropdown--button"
            :active="selectedRadius.value === option.value"
            @click="changeRadius(option)"
          >
            {{ option.name }}
          </b-dropdown-item-button>
        </b-dropdown>
        <FilterSwitch
          v-if="openingType"
          :section="openingType"
          :showLeftIcon="true"
          :disableFilters="disableFilters"
        />
        <FilterButtonDaysOpen
          v-if="daysOpen"
          :section="daysOpen"
          :showLeftIcon="true"
          :disableFilters="disableFilters"
        />
      </div>
      <div class="tag-buttons">
        <div class="tag-buttons-wrapper"
            v-if="tags">
          <TagButton
            v-for="(tag, tagIndex) in tags.options"
            :key="tagIndex"
            :section="tags"
            :tag="tag"
            :disableFilters="disableFilters"/>
          <FilterButtonGroupSize
            v-if="groupSize"
            :section="groupSize"
            :showLeftIcon="true"
            :disableFilters="disableFilters"
          />
          <FilterName :disableFilters="disableFilters" />

        </div>
      </div>
      <div class="filter-sidebar">
        <FilterSidebar :disableFilters="disableFilters"/>
      </div>
    </div>
    <div class="filter-buttons-wrapper__chevron-container">
      <font-awesome-icon
        class="filter-buttons-wrapper__chevron-container--icon"
        :icon="['far', 'chevron-right']"
        @click="handleChevronClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FilterSwitch from '@/components/header/filters/FilterSwitch'
import FilterButtonGroupSize from '@/components/header/filters/FilterButtonGroupSize'
import FilterButtonDaysOpen from '@/components/header/filters/FilterButtonDaysOpen'
import FilterName from '@/components/header/filters/FilterName'
import TagButton from '@/components/header/filters/TagButton'
import FilterSidebar from '@/components/header/filters/FilterSidebar'

export default {
  name: 'FilterButtons',

  components: {
    FilterSwitch,
    FilterName,
    FilterSidebar,
    TagButton,
    FilterButtonGroupSize,
    FilterButtonDaysOpen,
  },

  data: () => ({
    keyFilterTypes: [
      'days_open',
    ],
  }),

  computed: {
    ...mapGetters('filter', [
      'dynamicFilterSectionByKey',
      'filterTypeByArray',
    ]),
    ...mapGetters('garden', [
      'searchTypeValue',
    ]),
    ...mapGetters('location', [
      'radiusOtions',
      'selectedRadius',
      'useCurrentLocation',
    ]),

    tags() {
      return this.dynamicFilterSectionByKey('basic')
    },
    daysOpen() {
      return this.dynamicFilterSectionByKey('days_open')
    },
    groupSize() {
      return this.dynamicFilterSectionByKey('group_size')
    },
    openingType() {
      return this.dynamicFilterSectionByKey('opening_type')
    },

    disableFilters() {
      return this.$route.name === 'Garden'
    },
  },
  methods: {
    ...mapActions('location', [
      'selectRadius',
      'selectRadiusAndGetBounds',
    ]),

    changeRadius(radius) {
      this.selectRadiusAndGetBounds(radius)
    },

    handleChevronClick() {
      const scrollWrapper = document.querySelector('.filters');

      if (scrollWrapper) scrollWrapper.scrollLeft = 2500;
    },
  },
}
</script>

<style lang="scss">
.filter-buttons-wrapper {
  position: relative;
  justify-content: space-between;
  margin: 0 1rem 1rem 1rem;
  display: flex;
  align-items: center;

  &__chevron-container {
    display: none;
    cursor: pointer;

    @media (max-width: 1200px) {
      display: block;

      &--icon {
        margin: 0 0 0 10px;
      }
    }
  }

  .filters {
    display: flex;

    @media (max-width: 1200px) {
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .filter-buttons {
      display: flex;
    }

    .tag-buttons {
      .tag-buttons-wrapper {
        display: flex;
        border-left: 1px solid white;
        gap: 0.5rem;
        padding: 0 0.5rem;

        > button {
          width: max-content;
        }
      }
    }
  }

  .filter-sidebar {
    display: flex;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px;
    top: 0;
    right: -16px;

    .filter-sidebar-wrapper {
      width: 125px;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .dropdown-close {
    display:none;
    @include media-breakpoint-down(lg) {
      display:block;
      float: right;
      border: none;
      background: transparent;
      font-size: 0.8em;
    }

  }

  .radial-distance-dropdown {
    position: initial;
    margin-right: 0.5rem;
    border: 1px solid #1C2433;
    border-radius: 0.25rem;
    min-width: 128px;
    overflow: hidden;

    > .btn {
      background-color: white !important;
      padding: 6px 11px;
    }

    > ul {
      top: 26px !important;
    }

    &--button {
      background-color: white;
    }

    &--chevron {
      font-size: 12px;
    }
  }

  .filter-buttons-mobile {
    @include media-breakpoint-down(sm) {
      > :nth-child(1) { order: 5 }
      > :nth-child(2) { order: 2 }
      > :nth-child(3) { order: 3 }
      > :nth-child(4) { order: 1 }
      > :nth-child(5) { order: 4 }
    }
  }
}
</style>
