import { Capacitor } from '@capacitor/core';
import {
  clone,
  find,
  isEqual,
  merge,
} from 'lodash'
import {
  getLocation,
  getBounds,
  getCenterPoint,
  getLocationAndBounds,
  getFullBoundsFromNESW,
} from '@/services/location'
import GEO_LOCATION from '@/services/geolocation'
import router from '@/router/index'

export default {
  namespaced: true,

  state: {
    useCurrentLocation: false,
    userFullLocation: null,
    defaultUserFullLocation: {
      address_components: [
        {
          long_name: 'Your Current Location',
          types: [
            'current_geo_location',
          ],
        },
      ],
      formatted_address: 'Your Current Location',
      current: true,
      geometry: {
        bounds: null,
        location: null,
      },
    },
    locationEngland: {
      address_components: [
        {
          long_name: 'England',
          short_name: 'England',
          types: [
            'administrative_area_level_1',
            'political',
          ],
        },
        {
          long_name: 'United Kingdom',
          short_name: 'GB',
          types: [
            'country',
            'political',
          ],
        },
      ],
      formatted_address: 'England, UK',
      geometry: {
        bounds: {
          northeast: {
            lat: 55.8116597,
            lng: 1.7629159,
          },
          southwest: {
            lat: 49.8647411,
            lng: -6.4185458,
          },
        },
        location: {
          lat: 52.3555177,
          lng: -1.1743197,
        },
      },
      place_id: 'ChIJ39UebIqp0EcRqI4tMyWV4fQ',
    },
    userGeoLocation: null,
    userAddress: null,
    userGeoLocationBounds: null,

    selectedLocation: null,
    milesToMeters: 1609.34,
    // Radius in Miles
    radiusOtions: [
      {
        name: '1 miles',
        value: 1,
      },
      {
        name: '5 miles',
        value: 5,
      },
      {
        name: '10 miles',
        value: 10,
      },
      {
        name: '25 miles',
        value: 25,
      },
      {
        name: '50 miles',
        value: 50,
      },
      {
        name: '100 miles',
        value: 100,
      },
    ],
    // Radius in Miles
    selectedRadius: {
      name: '10 miles',
      value: 10,
    },
    watchInstance: null,
  },

  mutations: {
    toggleUseCurrentLocation(state) {
      state.useCurrentLocation = !state.useCurrentLocation
    },
    setUseCurrentLocation(state, trueOrFalse) {
      state.useCurrentLocation = trueOrFalse
    },
    setUserFullLocation(state, location) {
      state.userFullLocation = clone(location)
    },
    updateUserFullLocationBounds(state, bounds) {
      state.userFullLocation.geometry.bounds = clone(bounds)
    },
    updateSelectedLocation(state, selectedLocation) {
      const location = selectedLocation
      const time = Date.now()
      location.geometry.bounds.time = time
      location.geometry.location.time = time
      state.selectedLocation = location
    },
    setSelectedLocation(state, selectedLocation) {
      state.selectedLocation = selectedLocation
    },
    setUserGeoLocation(state, geolocation) {
      state.userGeoLocation = clone(geolocation)
    },
    setUserAddress(state, address) {
      state.userAddress = clone(address)
    },
    setUserGeoLocationBounds(state, bounds) {
      state.userGeoLocationBounds = clone(bounds)
    },
    setSelectedRadius(state, radius) {
      state.selectedRadius = radius
    },
    setWatchInstance(state, instance) {
      state.watchInstance = instance
    },
    clearSelectedLocation(state) {
      state.selectedLocation = null
    },
    clearWatchInstance(state) {
      state.watchInstance = null
    },
    clearUserFullLocation(state) {
      state.userFullLocation = null
    },
    clearUserGeoLocation(state) {
      state.userGeoLocation = null
    },
    clearUserGeoLocationBounds(state) {
      state.userGeoLocationBounds = null
    },
    clearState(state) {
      state.userFullLocation = null
      state.userGeoLocation = null
      state.userGeoLocationBounds = null
      state.watchInstance = null
      state.selectedLocation = null
    },
  },

  actions: {
    clearState: ({ commit }) => new Promise((resolve) => {
      commit('clearState')
      resolve()
    }),

    clearSelectedLocation: ({ commit }) => new Promise((resolve) => {
      commit('clearSelectedLocation')
      resolve()
    }),

    clearUserGeoLocation: ({ commit }) => new Promise((resolve) => {
      commit('clearUserGeoLocation')
      resolve()
    }),

    selectRadius: ({ commit }, selectedRadius) => new Promise((resolve) => {
      commit('setSelectedRadius', selectedRadius)
      resolve()
    }),

    selectRadiusAndGetBounds: ({ dispatch, state }, selectedRadius) => new Promise((resolve) => {
      dispatch('selectRadius', selectedRadius)
      if (state.selectedLocation?.geometry?.location) {
        const geometryLocation = clone(state.selectedLocation?.geometry?.location)
        const radius = clone(state.selectedRadius.value)
        if (!state.selectedLocation.current) {
          const fullLocation = clone(state.selectedLocation)
          dispatch('getSelectedLocationBoundsAndUpdate', {
            location: fullLocation,
            keepFilters: true,
            radiusChange: true,
          })
        } else {
          dispatch('getRadiusBounds', { location: geometryLocation, radius }).then((bounds) => {
            dispatch('setUserGeoLocationBounds', { bounds, keepFilters: true })
          })
        }
      }
      resolve()
    }),

    toggleUseCurrentLocation: ({
      commit,
      state,
      dispatch,
      rootGetters,
    }) => new Promise((resolve) => {
      commit('toggleUseCurrentLocation')
      if (state.useCurrentLocation) {
        dispatch('locateUserGeoLocation')
        dispatch('garden/updateSearchType', rootGetters['garden/locationSearchType'], { root: true })
      } else {
        const platform = Capacitor.getPlatform();

        if (state.watchInstance) {
          if (platform === 'web') GEO_LOCATION.clearWatch(state.watchInstance)
          if (platform === 'android' || platform === 'ios') GEO_LOCATION.clearWatch({ id: state.watchInstance })
        }

        commit('clearState')
        dispatch('garden/clearSearch', '', { root: true })
      }
      resolve()
    }),

    async setUseCurrentLocation({
      commit,
      state,
      dispatch,
    }, trueOrFalse) {
      commit('setUseCurrentLocation', trueOrFalse)
      if (trueOrFalse) {
        dispatch('locateUserGeoLocation', true)
        dispatch('garden/updateSearchType', 'current', { root: true })
      } else {
        const platform = Capacitor.getPlatform();

        if (state.watchInstance) {
          if (platform === 'web') GEO_LOCATION.clearWatch(state.watchInstance)
          if (platform === 'android' || platform === 'ios') await GEO_LOCATION.clearWatch({ id: state.watchInstance })
        }

        commit('clearState')
        dispatch('garden/clearSearch', '', { root: true })
      }
    },

    // This is only used on the details page as we want to deactivate the current location seaarch with out all of the events that normally follow that like
    // resetting filters, resetting the selected location which could cause the map to change etc
    switchOffCurrentLocationOnly: ({
      commit,
      state,
      dispatch,
    }) => new Promise((resolve) => {
      commit('setUseCurrentLocation', false)
      const platform = Capacitor.getPlatform();

      if (state.watchInstance) {
        if (platform === 'web') GEO_LOCATION.clearWatch(state.watchInstance)
        if (platform === 'android' || platform === 'ios') GEO_LOCATION.clearWatch({ id: state.watchInstance })
      }

      // commit('clearState')
      dispatch('garden/clearSearch', '', { root: true })
      resolve()
    }),

    // This is only used on the details page as we want to deactivate the current location seaarch with out all of the events that normally follow that like
    // resetting filters, resetting the selected location which could cause the map to change etc
    switchOnCurrentLocationOnly: ({
      commit,
      dispatch,
    }) => new Promise((resolve) => {
      commit('setUseCurrentLocation', true)
      dispatch('watchUserGeoLocation')
      resolve()
    }),

    setUserGeoLocation: ({
      commit,
      state,
      dispatch,
    }, location) => new Promise((resolve) => {
      const currentGeoLocation = clone(state.userGeoLocation)
      const fullLocation = clone(state.userFullLocation) ?? clone(state.defaultUserFullLocation)

      fullLocation.geometry.location = clone(location)

      if (!currentGeoLocation || (location.lat !== currentGeoLocation.lat || location.lng !== currentGeoLocation.lng)) {
        commit('setUserFullLocation', clone(fullLocation))

        commit('setUserGeoLocation', clone(location))
        dispatch('garden/updateSearchTerm', clone(fullLocation.formatted_address), { root: true })
        dispatch('getRadiusBounds', { location: state.userGeoLocation, radius: state.selectedRadius.value }).then((bounds) => {
          dispatch('setUserGeoLocationBounds', { bounds, keepFilters: location.keepFilters })
        })
      }
      resolve()
    }),

    setUserGeoLocationBounds: ({
      commit,
      state,
      dispatch,
    }, data) => new Promise((resolve) => {
      commit('updateUserFullLocationBounds', data.bounds)
      commit('setUserGeoLocationBounds', data.bounds)
      dispatch('updateSelectedLocation', {
        location: clone(state.userFullLocation),
        keepFilters: data.keepFilters,
      })

      resolve()
    }),

    async watchUserGeoLocation({ commit, state, dispatch }) {
      const platform = Capacitor.getPlatform();

      if (platform === 'web') {
        const watchInstance = await GEO_LOCATION.watchPosition(async (position) => {
          const coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            keepFilters: true,
          }

          if (coords.lat !== state.userGeoLocation.lat || coords.lng !== state.userGeoLocation.lng) {
            await dispatch('setUserGeoLocation', coords)
          }
        })
        commit('setWatchInstance', watchInstance)
        return;
      }

      const watchInstance = await GEO_LOCATION.watchPosition({
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 0,
      }, (position) => {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          keepFilters: true,
        }

        dispatch('setUserGeoLocation', coords)
      })
      commit('setWatchInstance', watchInstance)
    },

    async locateUserGeoLocation({ dispatch }, keepFilters = false) {
      const location = await getLocation()

      await dispatch('setUserGeoLocation', { ...location, keepFilters })

      dispatch('watchUserGeoLocation')
    },

    async locateAndGetBounds({ state, dispatch }) {
      const {
        location,
        bounds,
      } = await getLocationAndBounds(state.selectedRadius.value * state.milesToMeters)
      await dispatch('setUserGeoLocation', location)
      await dispatch('setUserGeoLocationBounds', { bounds })
      return bounds
    },

    async getRadiusBounds({ state }, { location: geoLocation, radius }) {
      const bounds = await getBounds(geoLocation, radius * state.milesToMeters)

      return bounds
    },

    async getSelectedLocationBoundsAndUpdate({
      state,
      dispatch,
    }, data) {
      let location = clone(data.location)
      if (location.place_id !== state.selectedLocation?.place_id || data.radiusChange) {
        const keepFilters = clone(data.keepFilters)

        if (location.address_components.length === 1 && location.address_components[0].short_name === 'GB') {
          location = clone(state.locationEngland)
        }

        const geometryLocation = clone(location.geometry.location)
        let geometryBounds = clone(location.geometry.viewport)

        if (!location.geometry.originalBounds) {
          location.geometry.originalBounds = geometryBounds
        } else {
          geometryBounds = clone(location.geometry.originalBounds)
        }

        let radius = clone(state.selectedRadius.value)

        const center = await getCenterPoint(geometryBounds)
        const ne = geometryBounds.northeast;

        const r = 3963.0;

        // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
        const radianConst = 57.2958;
        const lat1 = center.lat / radianConst;
        const lon1 = center.lng / radianConst;
        const lat2 = ne.lat / radianConst;
        const lon2 = ne.lng / radianConst;

        // distance = circle radius from center to Northeast corner of bounds
        const dis = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));

        radius = dis + state.selectedRadius.value
        await dispatch('getRadiusBounds', { location: geometryLocation, radius }).then(async (bounds) => {
          location.geometry.bounds = clone(bounds)

          await dispatch('updateSelectedLocation', {
            location,
            keepFilters,
          })
        })
      } else {
        await dispatch('garden/clearSearchResults', '', { root: true })
      }

      return true
    },

    updateSelectedLocation: ({
      state,
      commit,
      dispatch,
      rootGetters,
    }, data) => new Promise((resolve) => {
      if (!data.keepFilters) {
        dispatch('filter/resetFilters', [{}], { root: true })
        dispatch('filter/updateTag', { filter: 'days_open', type: 'text', options: 'next-7-days' }, { root: true })
        dispatch('filter/updateTag', { filter: 'opening_type', type: 'defaulted_toggle', options: 'opening' }, { root: true })
      }
      if (data.filterData) {
        dispatch('filter/updateTag', data.filterData, { root: true })
      }
      const term = data.location.address_components.length > 1
        ? `${data.location.address_components[0].long_name}, ${data.location.address_components[1].long_name}`
        : data.location.address_components[0].long_name

      dispatch('garden/updateSearchTerm', term, { root: true })
      dispatch('garden/clearSearchResults', '', { root: true })

      const location = clone(data.location)
      const geometryBounds = clone(data.location.geometry.bounds ?? data.location.geometry.viewport)

      const isPostalCode = find(data.location.types, function (type) { return type === 'postal_code' })

      location.geometry.bounds = geometryBounds
      const geometryBoundsTRBRBLTL = getFullBoundsFromNESW(geometryBounds)

      const boundsTLBR = clone(geometryBoundsTRBRBLTL)

      if (isPostalCode) {
        const border = 0.004;
        boundsTLBR.top_right.lat += border
        boundsTLBR.top_right.lng += border
        boundsTLBR.bottom_right.lng -= border
        boundsTLBR.bottom_right.lng -= border
        boundsTLBR.bottom_left.lng -= border
        boundsTLBR.bottom_left.lng -= border
        boundsTLBR.top_left.lat += border
        boundsTLBR.top_left.lng += border
      }

      commit('updateSelectedLocation', location)

      dispatch('map/addSearchBounds', geometryBoundsTRBRBLTL, { root: true })

      const searchBounds = `${geometryBoundsTRBRBLTL.top_right.lat},${geometryBoundsTRBRBLTL.top_right.lng},${geometryBoundsTRBRBLTL.bottom_left.lat},${geometryBoundsTRBRBLTL.bottom_left.lng}`

      let urlQuery = clone(rootGetters['filter/selectedFiltersQuery'])

      const selectedSort = rootGetters['sort/selectedSortValue']
      if (selectedSort) {
        urlQuery = merge(urlQuery, { sort_by: selectedSort })
      }

      urlQuery.search_term = term
      urlQuery.search_bounds = searchBounds

      const filters = {
      }
      filters['filter[geo_bounding_box]'] = searchBounds
      urlQuery = merge(urlQuery, filters)

      if (state.useCurrentLocation || !isEqual(router.currentRoute.query, urlQuery)) {
        dispatch('filter/addBoundsFilter', boundsTLBR, { root: true })
      } else {
        router.push({ name: 'List', query: urlQuery })
      }

      resolve()
    }),
  },

  getters: {
    useCurrentLocation: (state) => state.useCurrentLocation,
    locationEngland: (state) => state.locationEngland,
    userFullLocation: (state) => state.userFullLocation,
    selectedLocation: (state) => state.selectedLocation,
    selectedLocationLatLng: (state) => state.selectedLocation?.geometry?.location,
    selectedLocationBounds: (state) => state.selectedLocation?.geometry?.bounds,
    userGeoLocation: (state) => state.userGeoLocation,
    userGeoLocationBounds: (state) => state.userGeoLocationBounds,
    radiusOtions: (state) => state.radiusOtions,
    selectedRadius: (state) => state.selectedRadius,
  },
}
