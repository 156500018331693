import {
  clone,
  find,
  isEqual,
  isEmpty,
  merge,
  orderBy,
  toNumber,
} from 'lodash'
import api from '@/api/endpoints/garden'
import mapApi from '@/api/endpoints/map'
import router from '@/router/index'
import { getFullBoundsFromNESW, getFullBoundsFromTLBR } from '@/services/location'
import eventBus from '@/services/eventBus'

export default {
  namespaced: true,

  state: {
    searchTypes: [
      {
        name: 'Location',
        value: 'location',
        faIcon: ['far', 'map-marker-alt'],
      },
      {
        name: 'Garden',
        value: 'garden',
        faIcon: ['far', 'flower-daffodil'],
      },
    ],

    selectedSearchType: {
      name: 'Location',
      value: 'location',
      faIcon: ['far', 'map-marker-alt'],
    },
    searchModalShown: false,
    searchTerm: null,
    searchResults: null,
    garden: null,
    gardens: [],
    loadingGardenList: false,
    mapSearch: false,
  },

  mutations: {
    updateMapSearch(state, isMapSearch) {
      state.mapSearch = isMapSearch
    },
    updateLoadingGardenList(state, isLoading) {
      state.loadingGardenList = isLoading
    },
    updateSearchType(state, searchType) {
      if (state.selectedSearchType.value !== searchType.value) {
        state.searchTerm = null
      }
      if (searchType.value === 'garden') {
        state.searchResults = null
      }
      state.selectedSearchType = searchType
    },

    updateSearchModalShown(state, shown) {
      state.searchModalShown = shown
    },
    updateSearchTerm(state, searchTerm) {
      // replace all ios/iphoneX weirdness ie:''"" em dashes and ellipses
      if (searchTerm) {
        state.searchTerm = searchTerm
          .replace('”', '"')
          .replace('“', '"')
          .replace('’', "'")
          .replace('‘', "'")
          .replace(/[\u2018\u2019]/g, "'")
          .replace(/[\u201C\u201D]/g, '"')
          .replace(/[\u2013\u2014]/g, '-')
          .replace(/[\u2026]/g, '...')
      } else {
        state.searchTerm = searchTerm;
      }

      if (searchTerm === null) {
        state.searchResults = null
      }
    },
    updateSearchResults(state, searchResults) {
      state.searchResults = searchResults
    },
    setGarden(state, garden) {
      state.garden = garden
    },
    setGardens(state, gardens) {
      state.gardens = gardens
    },
    clearGarden(state) {
      state.garden = null
    },
    clearSearchResults(state) {
      state.searchResults = null
    },
    clearSearch(state) {
      state.searchTerm = null
    },
    clearState(state) {
      state.searchTerm = null
      state.gardens = []
      state.selectedSearchType = {
        name: 'Location',
        value: 'location',
        faIcon: ['far', 'map-marker-alt'],
      }
      state.searchResults = null
      state.loadingGardenList = false
    },
  },

  actions: {
    clearState: ({ commit }) => new Promise((resolve) => {
      commit('clearState')
      resolve()
    }),
    updateLoadingGardenList: ({ commit }, isLoading) => new Promise((resolve, reject) => {
      commit('updateLoadingGardenList', isLoading)
      resolve()
    }),
    updateMapSearch: ({ commit }, isMapSearch) => new Promise((resolve, reject) => {
      commit('updateMapSearch', isMapSearch)
      resolve()
    }),
    updateSearchType: ({
      state,
      commit,
      dispatch,
      getters,
      rootGetters,
    }, searchType) => new Promise((resolve) => {
      const useCurrentLocation = rootGetters['location/useCurrentLocation']

      if (useCurrentLocation && searchType !== 'current') {
        dispatch('location/setUseCurrentLocation', false, { root: true })
      }

      const newSearchType = searchType === 'current' ? getters.locationSearchType : searchType;
      const searchTypeChanged = state.selectedSearchType.value !== newSearchType.value;

      commit('updateSearchType', newSearchType);
      eventBus.$emit('search-type-changed')

      if (newSearchType !== 'location') {
        dispatch('map/clearSearchBounds', [{}], { root: true })
      }

      if (searchTypeChanged) {
        dispatch('filter/resetFilters', [{}], { root: true });
        dispatch('filter/updateTag', { filter: 'days_open', type: 'text', options: 'next-7-days' }, { root: true })
        dispatch('filter/updateTag', { filter: 'opening_type', type: 'defaulted_toggle', options: 'opening' }, { root: true })
      }

      resolve()
    }),
    clearGarden: ({ commit }) => new Promise((resolve) => {
      commit('clearGarden')
      resolve()
    }),
    fetchAllGardens: ({
      commit,
      dispatch,
      rootGetters,
      state,
    }) => new Promise((resolve, reject) => {
      if (!state.loadingGardenList) {
        dispatch('updateLoadingGardenList', true)
        const filters = rootGetters['filter/defaultFiltersQuery']
        const englandLocation = rootGetters['location/locationEngland']
        api.filter(filters, (response) => {
          const responseData = response.data
          commit('setGardens', responseData.results)

          eventBus.$emit('gardens-filtered')

          // Leaving this in here as this is what sets the home page map to zoomed in on available gardens only
          // Now changed to whole of england

          // const boundingBox = responseData.stats.geo_bounding_box
          // boundingBox.bounds.bottom_right.lat += 0.5
          // if (window.innerWidth < 700) {
          //   boundingBox.bounds.top_left.lat += 0.9
          //   boundingBox.bounds.top_left.lng -= 0.4
          // }
          // const bounds = clone(englandLocation.geometry.bounds)
          // const boundingBox = {
          //   bounds: {
          //     top_left: {
          //       lat: bounds.northeast.lat,
          //       lng: bounds.southwest.lng,
          //     },
          //     bottom_right: {
          //       lat: bounds.southwest.lat,
          //       lng: bounds.northeast.lng,
          //     },
          //   },
          // }
          const boundingBox = getFullBoundsFromNESW(clone(englandLocation.geometry.bounds))
          dispatch('updateLoadingGardenList', false)
          dispatch('map/addGeoBoundingBox', boundingBox, { root: true })
          resolve(response)
        }, (error) => reject(error))
      }
    }),
    fetchGarden: ({ commit }, id) => new Promise((resolve, reject) => {
      api.get(id, (response) => {
        const responseData = response.data.data
        commit('setGarden', responseData)
        resolve(response)
      }, (error) => reject(error))
    }),
    filterGardens: ({
      commit,
      dispatch,
      rootGetters,
      state,
    }, data) => new Promise((resolve, reject) => {
      if (!state.loadingGardenList) {
        dispatch('map/toggleRouteSetup', false, { root: true })
        dispatch('updateLoadingGardenList', true)
        const filters = rootGetters['filter/selectedFiltersQuery'];
        const filtersQueryCheck = rootGetters['filter/selectedFiltersQueryCheck'];
        const searchBounds = rootGetters['map/searchBoundsQuery'];
        const selectedSort = rootGetters['sort/selectedSortValue'];
        const hasSelectedSearchResult = searchBounds ? true : false;
        let urlQuery = {}

        if (state.searchTerm) {
          urlQuery = merge(urlQuery, { search_term: state.searchTerm })
        }
        if (searchBounds) {
          urlQuery = merge(urlQuery, { search_bounds: searchBounds })
        }

        if (selectedSort) {
          urlQuery = merge(urlQuery, { sort_by: selectedSort })
        }

        let urlQueryCheck = clone(urlQuery)
        if (filters) {
          urlQueryCheck = merge(urlQueryCheck, filtersQueryCheck)
          urlQuery = merge(urlQuery, filters)
        }

        if (!isEqual(router.currentRoute.query, urlQuery) && !isEqual(router.currentRoute.query, urlQueryCheck)) {
          router.push({ name: 'List', query: urlQuery })
        }

        api.filter(filters, (response) => {
          const responseData = response.data
          commit('setGardens', responseData.results)

          eventBus.$emit('gardens-filtered')
          let geoBoundingBox = null

          if (!hasSelectedSearchResult && (!data || (data && (!data.fromMap && !data.addGeoBoundingBox)))) {
            if (((state.searchTerm && state.selectedSearchType.value === 'garden') || !state.searchTerm) && !isEmpty(responseData.stats.geo_bounding_box)) {
              dispatch('map/toggleRouteSetup', true, { root: true })
              const { bounds } = responseData.stats.geo_bounding_box
              const { top_left: topleft, bottom_right: bottomright } = bounds
              geoBoundingBox = getFullBoundsFromTLBR({ topleft, bottomright })

              dispatch('map/addGeoBoundingBox', geoBoundingBox, { root: true })
            }
          } else {
            if (data && data.addGeoBoundingBox) {
              const { bounds } = data.addGeoBoundingBox
              const { top_left: topleft, bottom_right: bottomright } = bounds
              geoBoundingBox = getFullBoundsFromTLBR({ topleft, bottomright })
              dispatch('map/addGeoBoundingBox', geoBoundingBox, { root: true })
            }
          }

          dispatch('map/toggleRouteSetup', true, { root: true })

          dispatch('updateLoadingGardenList', false)
          resolve(response)
        }, (error) => reject(error))
      }
    }),
    search: ({ state, dispatch, rootGetters }) => new Promise((resolve) => {
      if (state.searchTerm) {
        if (state.selectedSearchType.value === 'garden') {
          dispatch('searchGardens')
        }
        if (state.selectedSearchType.value === 'location') {
          const selectedLocation = rootGetters['location/selectedLocation']

          if (state.searchResults && state.searchResults.length) {
            const result = state.searchResults[0]
            dispatch('location/getSelectedLocationBoundsAndUpdate', {
              location: result,
              keepFilters: true,
            }, { root: true })
          } else if (state.searchResults === null && selectedLocation) {
            const result = selectedLocation
            dispatch('location/getSelectedLocationBoundsAndUpdate', {
              location: result,
              keepFilters: true,
            }, { root: true })
          }
        }
      }
      resolve()
    }),
    searchLocations: async ({ commit, state }) => {
      if (state.searchTerm) {
        if (state.selectedSearchType.value === 'location') {
          const response = await mapApi.location({ address: state.searchTerm });
          commit('updateSearchResults', response.data.results)
        }
      }
    },
    searchGardens: ({
      dispatch,
      state,
    }) => new Promise((resolve) => {
      dispatch('location/clearSelectedLocation', '', { root: true })

      dispatch('filter/resetFilters', [{}], { root: true })

      dispatch('filter/updateTag', { filter: 'garden', type: 'text', options: state.searchTerm }, { root: true })
      dispatch('filter/updateTag', { filter: 'days_open', type: 'text', options: 'all' }, { root: true })
      dispatch('filter/updateTag', { filter: 'open_status', type: 'toggle', options: 'no-upcoming-openings' }, { root: true })
      dispatch('filterGardens')
      resolve()
    }),

    updateSearchTerm: ({ commit }, term) => new Promise((resolve) => {
      commit('updateSearchTerm', term)
      resolve()
    }),
    updateSearchModalShown: ({ commit }, shown) => new Promise((resolve) => {
      commit('updateSearchModalShown', shown)
      resolve()
    }),
    clearSearchResults: ({ commit }) => new Promise((resolve) => {
      commit('clearSearchResults')
      resolve()
    }),
    clearSearch: ({ commit, dispatch }) => new Promise((resolve) => {
      commit('clearSearch')
      dispatch('location/clearSelectedLocation', '', { root: true })
      resolve()
    }),
  },

  getters: {
    searchTypes: (state) => state.searchTypes,

    locationSearchType: (getters) => getters.searchTypes.find((type) => type.value === 'location'),

    gardenSearchType: (getters) => getters.searchTypes.find((type) => type.value === 'garden'),

    searchPlaceholder: (state) => `Search by ${state.selectedSearchType.value}`,

    selectedSearchType: (state) => state.selectedSearchType,

    unSelectedSearchType: (getters) => getters.searchTypes.find((type) => type.value !== getters.searchTypeValue),

    searchTypeValue: (state) => state.selectedSearchType.value,

    searchTerm: (state) => state.searchTerm,

    searchResults: (state) => state.searchResults,

    searchModalShown: (state) => state.searchModalShown,

    garden: (state) => state.garden,

    gardens: (state) => state.gardens,

    sortedGarden: (state, getters, rootState, rootGetters) => {
      const selectedSort = rootGetters['sort/selectedSort'];
      if (selectedSort.value) {
        return orderBy(state.gardens, selectedSort.value, selectedSort.direction);
      }
      return state.gardens
    },

    gardenById: (state) => (idToFind) => {
      return find(state.gardens, ['id', toNumber(idToFind)]) ?? null
    },

    loadingGardenList: (state) => state.loadingGardenList,

    mapSearch: (state) => state.mapSearch,
  },
}
