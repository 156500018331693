<template>
  <div id="app">
    <DefaultLayout/>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core'
import { InstallMode } from 'capacitor-codepush';

import DefaultLayout from '@/views/layouts/DefaultLayout'

export default {
  components: {
    DefaultLayout,
  },
  beforeCreate() {
    // set status bar colour to dark
    if (Capacitor.isNativePlatform()) {
      (async () => {
        const CodePush = await import('capacitor-codepush');
        const AppPlugin = await import('@capacitor/app');
        const codePushSyncOptions = { updateDialog: false, installMode: InstallMode.IMMEDIATE, ignoreFailedUpdates: false }
        // check for code updates and apply them if necessary
        await CodePush.codePush.sync(codePushSyncOptions);

        // add listener to check for code updates each time the app is brought
        // to the foreground
        AppPlugin.App.addListener('appStateChange', async (newState) => {
          if (newState.isActive) {
            await CodePush.codePush.sync(codePushSyncOptions);
          }
        });
      })();
    }
  },
}
</script>
