import { api } from '../index'

export default {
  all(success, failure) {
    return api.get('/gardens')
      .then((response) => success(response))
      .catch((error) => failure(error))
  },
  get(gardenId, success, failure) {
    return api.get(`/gardens/${gardenId}`)
      .then((response) => success(response))
      .catch((error) => failure(error))
  },
  filter(filters, success, failure) {
    return api.get('/gardens', {
      params: filters,
    })
      .then((response) => success(response))
      .catch((error) => failure(error));
  },
  search(searchParams, success, failure) {
    return api.get('/search/', {
      params: searchParams,
    })
      .then((response) => success(response))
      .catch((error) => failure(error))
  },
}
