<template>
  <div class="tag-button-wrapper">
    <b-button
      class="filter-button"
      variant="outline-secondary"
      :pressed="selected"
      :disabled="disableFilters"
      @click="selectTag()">
    {{ tag.label }}</b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TagButton',

  props: {
    tag: Object,
    section: Object,
    disableFilters: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('filter', [
      'selectedFilters',
      'filterSelected',
    ]),

    selected() {
      return this.filterSelected(this.section.filter, this.tag.value)
    },
  },

  methods: {
    ...mapActions('filter', [
      'addFilter',
    ]),

    selectTag() {
      this.addFilter({ filter: this.section.filter, type: this.section.display_type, options: [this.tag.value] })
    },
  },
}
</script>

<style scoped lang="scss">
.tag-button-wrapper {
  .btn {
    width: max-content;
    &.btn-outline-primary {
        &:focus,
        &.active {
          &:hover {
            border-color: unset !important;
          }
        }
    }
  }
}
</style>
