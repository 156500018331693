<template>
  <div
    class="search-wrapper"
    :class="{ 'is-search-page': isSearchPage }"
  >
    <div class="search-input-wrapper">
      <b-input-group>
        <template #prepend>
          <b-dropdown
            variant="secondary"
            class="search-types-dropdown"
            @show="searchTypeFocus()">
            <template
              #button-content>
              <font-awesome-icon :icon="selectedSearchType.faIcon"/>
              <span class="d-none d-md-inline">{{ selectedSearchType.name }}</span>
            </template>
            <b-dropdown-item-button
              v-for="(option, optionIndex) in searchTypes"
              :key="optionIndex"
              @click="setSearchType(option)"
              :active="selectedSearchType.name === option.name"
            >
              <font-awesome-icon :icon="option.faIcon"/>
              {{ option.name }}
            </b-dropdown-item-button>
          </b-dropdown>
          <span
            class="input-group-text using-current-location"
            v-if="useCurrentLocation">
            <font-awesome-icon :icon="['far', 'location']"/>
          </span>
        </template>

        <template>
          <b-form-input
            id="searchInput"
            ref="searchInput"
            class="search-input-component"
            :class="{ 'using-current-location': useCurrentLocation }"
            v-model="searchValue"
            :placeholder="searchPlaceholder"
            @click="searchInputFocused()"
            @input="debounceSearchLocations()"
            v-on:keyup.enter="handleEnterEvent">
          </b-form-input>

          <div id="search-results-dropdown" v-if="searchResults">
            <ul
              class="dropdown-menu"
              :class="[showResultsDropDown ? 'show' : 'hide']">
              <li
                v-for="(result, resultIndex) in searchResults"
                :key="resultIndex">
                <div class="dropdown-item" @click="selectOption(result)">
                  {{ result.address_components[0] && result.address_components[0].long_name ? result.address_components[0].long_name : null }}
                  {{ result.address_components[1] && result.address_components[1].long_name ? `, ${result.address_components[1].long_name}` : null }}
                </div>
              </li>
            </ul>
          </div>
        </template>

        <b-input-group-append>
          <b-dropdown
            variant="secondary"
            class="search-types-dropdown d-none d-lg-inline-flex"
            :disabled="searchTypeValue === 'garden'"
            v-b-tooltip.hover.bottom="{ customClass: 'ngs-dark-tooltip' }"
            title="Distance">
            <template #button-content>
              <font-awesome-icon :icon="['far', 'directions']"/>
              <span class="d-none d-sm-inline">
                {{ selectedRadius.name }}
              </span>
            </template>
            <b-dropdown-item-button
              v-for="(option, optionIndex) in radiusOtions"
              :key="optionIndex"
              :active="selectedRadius.value === option.value"
              @click="changeRadius(option)"
            >
              {{ option.name }}
            </b-dropdown-item-button>
          </b-dropdown>
          <b-button
            class="your-location d-none d-lg-inline-flex"
            variant="secondary"
            :pressed="useCurrentLocation"
            @click="toggleUseCurrentLocation()"
            v-b-tooltip.hover.bottom="{ customClass: 'ngs-dark-tooltip' }"
            title="Your location"
          >
            <font-awesome-icon :icon="['far', 'location']" class="align-self-center" />
          </b-button>
          <b-button
            class="search-button"
            variant="primary"
            @click="search()"
            :disabled="useCurrentLocation"
          >
            <font-awesome-icon :icon="['far', 'search']"/>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash';

export default {
  name: 'Search',

  props: {
    isSearchPage: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    typing: false,
  }),

  computed: {
    ...mapGetters('garden', [
      'searchPlaceholder',
      'searchTypes',
      'selectedSearchType',
      'searchTypeValue',
      'searchTerm',
      'searchResults',
      'searchModalShown',
    ]),

    ...mapGetters('location', [
      'radiusOtions',
      'selectedRadius',
      'useCurrentLocation',
    ]),

    searchValue: {
      get() {
        return this.searchTerm
      },
      set(value) {
        this.$store.commit('garden/updateSearchTerm', value)
      },
    },
    showResultsDropDown() {
      return !!(this.searchResults && this.searchResults.length);
    },
  },

  watch: {
    searchValue(newValue) {
      if (!newValue) {
        this.$store.commit('garden/updateSearchTerm', null)
      }
    },
    selectedSearchType(newSearchType, oldSearchType) {
      if (newSearchType.value !== oldSearchType.value) {
        document.getElementById('searchInput').focus()
      }
    },
  },

  methods: {
    ...mapActions('garden', [
      'updateSearchType',
      'search',
      'searchLocations',
      'updateSearchModalShown',
    ]),

    ...mapActions('location', [
      'selectRadius',
      'selectRadiusAndGetBounds',
      'setUseCurrentLocation',
      'getSelectedLocationBoundsAndUpdate',
    ]),

    ...mapMutations('garden', [
      'updateSearchTerm',
    ]),

    handleEnterEvent() {
      const searchInputs = document.querySelectorAll('.search-input-component');

      this.search();

      if (searchInputs?.length) {
        if (window.innerWidth >= 992) {
          searchInputs[0].blur()
        } else {
          searchInputs[1].blur()
        }
      }
    },
    debounceSearchLocations: debounce(function () {
      this.searchLocations(this.searchValue)
    }, 300),

    toggleUseCurrentLocation() {
      this.setUseCurrentLocation(!this.useCurrentLocation)
    },
    searchTypeFocus() {
      if (this.useCurrentLocation) {
        this.setUseCurrentLocation(false)
      }
    },
    async setSearchType(option) {
      await this.updateSearchType(option)
      this.search()
    },
    changeRadius(radius) {
      this.selectRadiusAndGetBounds(radius)
    },

    selectOption(result) {
      this.getSelectedLocationBoundsAndUpdate({
        location: result,
        keepFilters: true,
      })
    },
    searchInputFocused() {
      if (this.useCurrentLocation) {
        this.setUseCurrentLocation(false)
      }
      this.activateSearchModal()
    },
    activateSearchModal() {
      if (!this.searchModalShown) {
        this.$bvModal.show('search-modal')
        this.updateSearchModalShown(true)
      }
    },
  },
}
</script>

<style lang="scss">
.search-types-dropdown {
  ul.dropdown-menu {
    -webkit-box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
    -moz-box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
    box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
    border: solid 1px #000;
    padding: 0;
    margin: 0.5rem 0 0;

    li {
      &:not(:last-child) {
        border-bottom: solid 1px #000;
      }
      button.dropdown-item {
        &.active {
          background-color: $color-yellow-main;
          color: #000;
        }

        &:not(.active) {
          &:focus,
          &:hover {
            background-color: #fff !important;
            color: #000 !important;
          }
        }
      }
    }
  }
}

.search-wrapper {
  flex: 1;

  &.is-search-page {
    .search-input-wrapper {
      margin: auto;

      #search-results-dropdown {
        left: 114px;
      }

      .input-group {
        border: none;

        .input-group-prepend {
          > .dropdown {
            > button {
              &:after {
                margin-left: 3px;
              }

              @include media-breakpoint-down(sm) {
                width: 115px;
              }

              span {
                display: inline !important;
              }
            }
          }
        }
      }
    }
  }

  .search-input-wrapper {
    @include media-breakpoint-up(md) {
      max-width: 800px;
    }

    .input-group {
      justify-content: center;
      position: relative;
      border: 1px solid $color-grey-dark;
      border-radius: 5px;

      .input-group-prepend {
        .dropdown-menu {
          min-width: 115px;
          background-color: white;

          .dropdown-item {
            padding: 6px 16px;
          }
        }
        > .dropdown {
          &.show {
            > button {
              background-color: white;
              border: 1px solid $color-grey-dark;
              &.active {
                background-color: $color-yellow-main;
                color: #000;
              }
            }
          }

          > button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-right: 1px solid $color-grey-dark;
            width: 115px;
            z-index: 50;

            @include media-breakpoint-down(sm) {
              width: 52px;
            }

            span {
              margin-left: 3px;
            }

            &:after {
              border-style: solid;
              border-width: 5px 5px 0 5px;
              border-color: black transparent transparent transparent;
              margin: 0 0 0 2px;
            }

            &:active,
            &:hover,
            &:focus {
              z-index: 50;
              box-shadow: none;
              border: 1px solid $color-grey-dark;
            }
          }
        }
        .input-group-text {
          &.using-current-location {
            border-right: none;
            background-color: #fff;
            padding: 0 0 0 10px;
          }
        }
      }
    }

    #searchInput {
      &:focus {
        box-shadow: none;
        border: 1px solid #ced4da;
      }
      &.using-current-location {
        border-left: none;
      }
    }

    .input-group-append {
      .dropdown-menu {
        min-width: 115px;
        background-color: white;

        .dropdown-item {
          padding: 6px 16px;
        }
      }

      > .dropdown {
        &.show {
          > button {
            background-color: white;
            border: 1px solid $color-grey-dark;
            &.active {
              background-color: $color-yellow-main;
              color: #000;
            }
          }
        }

        > button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 115px;
          z-index: 50;

          @include media-breakpoint-down(sm) {
            width: 52px;
          }

          span {
            margin-left: 3px;
          }

          &:after {
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: black transparent transparent transparent;
            margin: 0 0 0 2px;
          }

          &:active,
          &:hover,
          &:focus {
            z-index: 50;
            box-shadow: none;
            border: 1px solid $color-grey-dark;
            border-right: none;
          }
        }
      }
      .btn {
        border-left: 1px solid $color-grey-dark;
      }

      .search-button {
        &:active,
        &:hover,
        &:focus {
          z-index: 50;
          box-shadow: none;
          border: 1px solid $color-grey-dark;
          background-color: $primary;
        }
      }

      .your-location {
        &:hover{
          z-index: 50;
          box-shadow: none;
          border: 1px solid $color-grey-dark;
        }

        &:focus {
          border-right: 1px solid $color-grey-dark;
          background-color: #ececec;
        }

        &.active {
          border: 1px solid $primary;
          border-left: 1px solid $color-grey-dark;
          background-color: $primary;

          &:focus {
            border-right: 1px solid $color-grey-dark;
          }
          &:hover{
            border: 1px solid $color-grey-dark;
          }
        }
      }
    }
  }

  #search-results-dropdown {
    position: absolute;
    left: 114px;
    top: 37px;

    @include media-breakpoint-down(sm) {
      left: 51px;
    }

    .dropdown-menu {
      -webkit-box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
      -moz-box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
      box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #ffffff00;
      position: absolute;
      z-index: 1000;
      display: none;
      min-width: 10rem;
      padding: 0;
      margin: 0;
      font-size: 1rem;
      color: $color-grey-medium;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-top: none;
      border-radius: 0.25rem;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      &.show {
        display: block;
        position: absolute;
        transform: translate3d(0px, 0px, 0px);
        top: 0px;
        left: 0px;
        will-change: transform;
      }

      li {
        .dropdown-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 400;
          color: $color-grey-medium;
          width: 100%;
          padding: 0.25rem 1rem;
          clear: both;
          text-align: inherit;
          text-decoration: none;
          white-space: nowrap;
          background-color: transparent;
          border: 0;

          &:hover {
            color: #1e2125;
            background-color: #e9ecef;
          }
        }
      }
    }
  }
}
</style>
