import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL
export const apiUrl = API_URL

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
})

export const geocodingApi = axios.create({
  baseURL: process.env.VUE_APP_GOOGLE_MAPS_GEOCODING_API_URL,
  headers: {
    Accept: 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
})
